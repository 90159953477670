<template>
    <div class="content-wall">
        <div class="content" :class="diyiti ? 'show' : ''">
            <div class="question-content">
                <Label></Label>
                <div class="container">
                    <div id="question">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Label from '@/components/PC/questionType/Label.vue'

    export default {
        name: 'CalculatorMain',
        data() {
            return {
                diyiti: false,
            }
        },
        components: {
            Label,
        },
        mounted() {
            this.diyiti = true
        },

        methods: {},
    }
</script>

<style lang="less" scoped>
    .content {
        width: 12.2rem;
        height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: center;*/
        overflow: hidden;
    }

    .content-wall {
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .question-content {
        width: 100%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0.6rem 0.8rem;
        border-radius: 20px;
        background-color: #358cf3;
        position: relative;
        margin-top: 0.35rem;
    }

    .container {
        width: 100%;
        height: 100%;
        #question {
            background-color: #fff;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 0.4rem 1rem 0.4rem;
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #1cd48d;
            }
            /deep/.ant-form-item label {
                font-size: 0.2rem !important;
                margin-top: 0.2rem !important;
                height: auto !important;
            }
            /deep/.button-view .ant-btn {
                border-radius: 0.08rem;
            }

            /deep/.ant-form-item .ant-radio-wrapper span {
                height: auto !important;
            }
        }
    }

    .container::-webkit-scrollbar {
        display: none;
    }
</style>
